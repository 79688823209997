import { useDayjs } from '#dayjs'
import { openDB } from 'idb'
import { documentStatus } from '@/utils/constants'

const dayjs = useDayjs()

// 👉 IsEmpty
export const isEmpty = (value: unknown): boolean => {
  if (value === null || value === undefined || value === '')
    return true

  return !!(Array.isArray(value) && value.length === 0)
}

// 👉 IsNullOrUndefined
export const isNullOrUndefined = (value: unknown): value is undefined | null => {
  return value === null || value === undefined
}

// 👉 IsEmptyArray
export const isEmptyArray = (arr: unknown): boolean => {
  return Array.isArray(arr) && arr.length === 0
}

// 👉 IsObject
export const isObject = (obj: unknown): obj is Record<string, unknown> =>
  obj !== null && !!obj && typeof obj === 'object' && !Array.isArray(obj)

// 👉 IsToday
export const isToday = (date: Date) => {
  const today = new Date()

  return (
    date.getDate() === today.getDate()
    && date.getMonth() === today.getMonth()
    && date.getFullYear() === today.getFullYear()
  )
}

export const checkNew = (date: string): boolean => {
  const calDate = dayjs(date).add(7, 'd')
  if (calDate >= dayjs())
    return true

  return false
}

export const fileType = (name: string): string => {
  const type = name.slice(name.lastIndexOf('.'))
  switch (type.toLowerCase()) {
    case '.pdf':
      return 'pdf'
    case '.doc':
    case '.docx':
    case '.txt':
      return 'file-text'
    case '.xls':
    case '.xlsx':
    case '.csv':
      return 'csv'
    case '.jpeg':
    case '.jpg':
    case '.gif':
    case '.png':
      return 'png'
    default:
      return 'files'
  }
}

const getCoords = () => {
  return new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(position => {
      resolve(position.coords)
    }, error => reject(error)),
  )
}

export const getPosition = async () => {
  try {
    const position = await getCoords()

    useCookie<Partial<object>>('positionCenter').value = { lat: position?.latitude, lng: position?.longitude }
  }
  catch (error) {
    useCookie<Partial<object>>('positionCenter').value = { lat: 34.686240914597, lng: 135.502380745254 }
    console.log(error)
  }
}

export const setDisableDate = (place: any, regular_holiday: any) => {
  let disable = []
  if (place.disable_dates.length)
    disable = place.disable_dates.map((d: any) => d.disable_date)

  const holidays = { repeat: { weekdays: regular_holiday } }

  disable.push(holidays)

  return disable
}

export const setAttributes = (place: any) => {
  const customDataComment = place.comments ? place.comments.map((d: any) => ({ date: d.date, comment: d.comment })) : []
  const customDataDisable = place.disable_with_comment ? place.disable_with_comment.map((d: any) => ({ date: d.disable_date, comment: d.comment })) : []

  return [
    {
      dot: true,
      popover: { visibility: 'hover' },
      dates: place.comments ? place.comments.map((d: any) => d.date) : [],
      customData: customDataComment,
    },
    {
      dot: {
        color: 'red',
      },
      popover: { visibility: 'hover' },
      dates: place.disable_with_comment ? place.disable_with_comment.map((d: any) => d.disable_date) : [],
      customData: customDataDisable,
    },
  ]
}

export const mapProgressColor = (progress: string) => {
  switch (progress) {
    case 'エントリー前':
      return 'background: #FFCCFF;'
    case 'エントリー中':
      return 'background: #99FF99;'
    case 'エントリーまち':
      return 'background: #CCFFFF;'
    case 'キャンセル処理待ち':
    case '取り下げ依頼':
      return 'background:#FFC000;'
    case 'キャンセル処理中':
    case '取り下げ処理中':
      return 'background:#A6A6A6;'
    case '無償キャンセル':
    case '有償キャンセル':
    case '①CL 仕なし・売なし':
    case '②CL 仕あり・売あり':
    case '③CL 仕なし・売あり':
    case '④CL 仕あり・売なし':
    case '取り下げ完了':
      return 'color:#FFFFFF;background:#000000;'
    case '提案中':
      return 'background: #00B0F0;'
    case '再提案前':
    case '施設NG':
    case 'クライアントNG':
    case '商材NG':
    case '日程NG':
    case 'その他NG':
    case '同商材先約NG':
      return 'color:#FFFFFF;background:#FF0000;'
    case '確定':
      return 'color:#000000;background: #FFFFFF;'
    case '予約済':
      return 'color:#000000;background: #FFFF00;'
    default:
      return 'color:#000000;background: #FFFFFF;'
  }
}

export const mapColor = (progress: string) => {
  switch (progress) {
    case 'エントリー前':
      return '#FFCCFF'
    case 'エントリー中':
      return '#99FF99'
    case 'エントリーまち':
      return '#CCFFFF'
    case 'キャンセル処理待ち':
    case '取り下げ依頼':
      return '#FFC000'
    case 'キャンセル処理中':
    case '取り下げ処理中':
      return '#A6A6A6'
    case '無償キャンセル':
    case '有償キャンセル':
    case '①CL 仕なし・売なし':
    case '②CL 仕あり・売あり':
    case '③CL 仕なし・売あり':
    case '④CL 仕あり・売なし':
    case '取り下げ完了':
      return '#000000'
    case '提案中':
      return '#00B0F0'
    case '再提案前':
    case '施設NG':
    case 'クライアントNG':
    case '商材NG':
    case '日程NG':
    case 'その他NG':
    case '同商材先約NG':
      return '#FF0000'
    case '確定':
      return '#FFFFFF'
    case '予約済':
      return '#FFFF00'
    default:
      return '#FFFFFF'
  }
}

export const mapDocumentProgressColor = (progress: string) => {
  switch (progress) {
    case '未作成':
      return 'background: #FFCCFF;'
    case '施設書類待ち':
      return 'background: #FFC000;'
    case '作成要':
      return 'background: #99FF99;'
    case '書類提出前':
    case '再作成書類提出前':
      return 'color:#000000;background: #FFFF00;'
    case '開催情報依頼中':
    case '再作成情報依頼中':
      return 'background: #CCFFFF;'

    // case '完了':
    //   return 'color:#000;background: #FFF;'
    case '最終確認中':
      return 'background: #A6A6A6;'
    case '再作成要':
      return 'background: #FFCCFF;'

    // case '再完了':
    //   return 'color:#000000;background: #f9f9f9;'
    default:
      return 'color:#000000;background: #f9f9f9;'
  }
}

export const mapDocumentStatus = (progress: string) => {
  switch (progress) {
    case '予約済':
      return [
        '予約済',
        '開催情報依頼中',
        '施設書類待ち',
        '作成要',
        '書類提出前',
        '二次書類待ち',
      ]
    case '未作成':
      return [
        '未作成',
        '開催情報依頼中',
        '施設書類待ち',
        '作成要',
        '書類提出前',
        '二次書類待ち',
      ]
    case '開催情報依頼中':
    case '施設書類待ち':
    case '作成要':
    case '書類提出前':
    case '二次書類待ち':
      return [
        '開催情報依頼中',
        '施設書類待ち',
        '作成要',
        '書類提出前',
        '二次書類待ち',
      ]
    case '完了':
    case '再作成要':
    case '完了報告作成':
    case '再作成情報依頼中':
    case '再作成書類提出前':
      return ['完了', '再作成要', '完了報告作成', '再作成情報依頼中', '再作成書類提出前']
    default:
      return documentStatus
  }
}

export const mapSale = (sale: string) => {
  switch (sale) {
    case '0':
      return '通常'
    case '1':
      return '営業'
    case '2':
      return '業務'
    case '3':
      return 'CO'
    case '4':
      return '委託'
    case '5':
      return '施設'
    case '6':
      return '再提案'
    default:
      return ''
  }
}

export const getItemFromStore = async () => {
  const db = await openDB('bashotoru-firebase', 1)

  if (db.objectStoreNames.length) {
    // Get a value from the object store by its primary key value:
    const notification = await db.getAll('notification')

    db.clear('notification')

    return notification
  }

  return []
}

export const getAdmins = async () => {
  const adminStore = useAdminStore()

  return await adminStore.withName()
}
